/* ChatWidget.css */

.chat-widget {
    position: fixed;
    bottom: 120px; /* Adjust this value based on the height of the x button */
    right: 55px; /* Adjust this to provide a little space on the right */
    background-color: #151719;
    border: 1px solid #5D5DFF;
    border-radius: 20px;
    width: 90vw;  /* Responsive width */
    max-width: 360px; /* Maximum width so it doesn't get too large on big screens */
    height: 80vh; /* Responsive height */
    max-height: 550px; /* Maximum height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
}

    
  .chat-header {
    background-color: #5D5DFF;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height:35px;
  }
  
  .chat-content {
    padding: 10px;
    height: calc(100% - 105px);
    overflow-y: scroll;
    box-sizing: border-box;
  }
  
  .chat-messages {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .chat-message {
    display: flex;
    justify-content: flex-end;
    margin: 0px 0;
  }
  
  .message-bubble {
    background-color: #2979FF;
    color: white;
    border-radius: 15px;
    padding: 8px 10px 0px 0px; /* Adjusted padding */
    max-width: 70%;
    word-wrap: break-word;
    line-height: 1.4;
    white-space: pre-wrap;
    margin: 0; /* Ensure no default margin */

  }
  
  .chat-input {
    padding: 5px;
    background-color: #151719;
    border-top: 1px solid #5D5DFF;
    height: 60px;
    box-sizing: border-box;
    justify-content: space-between;

  }
  
  .chat-input input {
    padding: 10px;
    border: 2px solid #ffff;
    border-radius: 20px;
    font-size: 1em;
    width: 70%; /* Responsive width */
    outline: none;
    margin: 2px;
    background-color: #33363A;
    color: #ffffff;
    margin-left: 10px;

}
  
  .chat-input input:focus {
    border-color: #5D5DFF;
  }
  
  
  .chat-input button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #5D5DFF;
    color: white;
    cursor: pointer;
    margin-left: 15px;
    width:'30%'
  }
  
  .chat-input button:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
  
  
  /* Custom scroll bar */
  .chat-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .chat-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .ai-profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .chat-message {
    display: flex;
    margin: 10px;
  }
  
  .chat-message.user {
    justify-content: flex-end;
  }
  
  .chat-message.assistant {
    justify-content: flex-start;
    align-items: center;
  }
  
  .message-bubble {
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .chat-message.user .message-bubble {
    background-color: #26B4E0;
    color: white;
  }
  
  .chat-message.assistant .message-bubble {
    background-color: #33363A;
    color: #ffffff;
  }
  
  .typing-gif{
    height: 50px;
    width:75px
  }
  
  
  .option-button {
    margin: 5px;
    padding: 5px 10px;
    background-color: #5D5DFF;
    border: none;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    display: inline-block;
  }
  
  .option-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }


  /* Media queries for specific adjustments at certain screen sizes */
@media (max-width: 768px) { /* Adjust for tablets and smaller screens */
    .chat-widget {
        width: 95vw;
        bottom: 25px; /* Adjust this value based on the height of the x button */
        right: 20px; /* Adjust this to provide a little space on the right */
        border-radius: 20px;

    }

    .chat-message .message-bubble {
        max-width: 70%;
    }

    .chat-input input {
        width: 85%;
    }
}

@media (max-width: 480px) { /* Adjust for mobile phones */
    .chat-widget {
        width: 100vw;
        height: 70vh;
        border-radius: 0;
        bottom: 25px; /* Adjust this value based on the height of the x button */
        right: 20px; /* Adjust this to provide a little space on the right */
        border-radius: 20px;

    }

    .chat-message .message-bubble {
        max-width: 60%;
    }

    .chat-input input {
        width: 80%;
    }
}